import React, { useState } from 'react'; 
import "./leftbar.scss";
import { NavLink } from 'react-router-dom';

import jobs from '../../assets/jobs.svg';
import messages from '../../assets/messages.svg';
import network from '../../assets/network.svg';
import support from '../../assets/support.svg';
import IconButton from '@mui/material/IconButton';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const NewPost = ({ onClose }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle post submission
    onClose();
  };

  return (
    <div className="container">
    <div className="new-post">
      <h2>New Post</h2>
      <form onSubmit={handleSubmit}>
        <input class="title" type="text" placeholder="Title" />
        <input class="post" type="text" placeholder="Write your post here..." />
        <div className="file-input">
          <label className="file" htmlFor="file">Attach file</label>
          <input type="file" id="file" onChange={handleFileChange} />
          {file && <p>{file.name}</p>}
        </div>
        <button class="submit">Submit</button>
      </form>
    </div>
    </div>
  );
};

const Leftbar = () => {
  const [showNewPost, setShowNewPost] = useState(false);

  const handleNewPostClick = () => {
    setShowNewPost(true);
  };

  const handleCloseNewPost = () => {
    setShowNewPost(false);
  };

  return (
    <div className="leftbar-container">
      <div className={`overlay ${showNewPost ? "show" : ""}`} onClick={handleCloseNewPost} />
      <div className="leftbardesktop">
        <button onClick={handleNewPostClick}>+</button>
        {showNewPost && <NewPost onClose={handleCloseNewPost} />}
        <NavLink to="/jobs" style={{ textDecoration: "none"}}><img src={jobs} alt="Jobs"/></NavLink>
        <NavLink to="/home" style={{ textDecoration: "none"}}><img src={messages} alt="Messages"/></NavLink>
        <NavLink to="/notifications" style={{ textDecoration: "none"}}><img src={network} alt="Network"/></NavLink>
        <NavLink to="/profile" style={{ textDecoration: "none"}}><img src={support} alt="Support"/></NavLink>
        <h3>©Tadpole, Inc. 2022.<br/>Leap Forward.</h3>
      </div>

      <div className={`overlay ${showNewPost ? "show" : ""}`} onClick={handleCloseNewPost} />
      <div className="bottombarmobile">
        <NavLink to="/home" style={{ color: "black", textDecoration: "none" }}><IconButton color="inherit"><HomeOutlinedIcon style={{ fontSize: 40 }}/></IconButton></NavLink>
        <NavLink to="/jobs" style={{ color: "black", textDecoration: "none" }}><IconButton color="inherit"><WorkOutlinedIcon style={{ fontSize: 37 }}/></IconButton></NavLink>
        <button className="newpost" onClick={handleNewPostClick}>+</button>
        {showNewPost && <NewPost onClose={handleCloseNewPost} />}
        <NavLink to="/messages" style={{ color: "black", textDecoration: "none" }}><IconButton color="inherit"><MessageOutlinedIcon style={{ fontSize: 37 }}/></IconButton></NavLink>
        <NavLink to="/network" style={{ color: "black", textDecoration: "none" }}><IconButton color="inherit"><PersonOutlinedIcon style={{ fontSize: 40 }}/></IconButton></NavLink>      
      </div>
    </div>
  );
};

export default Leftbar;