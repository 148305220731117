import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import "./joblistings.scss";


const firebaseConfig = {
    apiKey: "AIzaSyBvSzeXwTOzVDy_yWlAXWc3ZrVtAvM23eU",
    authDomain: "tadpole-f221d.firebaseapp.com",
    projectId: "tadpole-f221d",
    storageBucket: "tadpole-f221d.appspot.com",
    messagingSenderId: "277703829351",
    appId: "1:277703829351:web:361b5970c1fbd6442fb1c2",
    measurementId: "G-6X65S5CK0F"
};

function Joblistings() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    // Get a reference to the jobs collection in the Firestore database
    const db = getFirestore(app);
    const jobsCollection = collection(db, 'jobs');

    // Query the database to retrieve the job data
    getDocs(jobsCollection).then((querySnapshot) => {
      const jobsData = [];
      querySnapshot.forEach((doc) => {
        jobsData.push(doc.data());
      });
      setJobs(jobsData);
    });
  }, []);

  return (
    <div className="joblist">
        <div className="joblistmobile">
            <div className="container">
                {jobs.map((job) => (
                <div key={job.id}>
                    <h2>{job.title}</h2>
                    <p>{job.description}</p>
                </div>
                ))}
            </div>
        </div>

        <div className="joblistdesktop">
            <div className="container">
                {jobs.map((job) => (
                <div className="job" key={job.id}>
                    <h2>{job.title}</h2>
                    <p>{job.description}</p>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}
export default Joblistings;
