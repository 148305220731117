import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';

import Landing from './pages/landing/Landing';
import Profile from './pages/profile/Profile';
import Register from './pages/register/Register';
import About from './pages/about/About';
import Mission from './pages/mission/Mission';
import Login from './pages/login/Login';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import Careers from './pages/careers/Careers';
import Community from './pages/community/Community';
import Jobs from './pages/jobs/Jobs';

function App() {
  const [auth, setAuth] = useState(null);

  // Initialize auth object when component mounts
  useEffect(() => {
    const auth = getAuth();
    setAuth(auth);
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          
          <Route exact path="/about" element={<About />} />
          <Route exact path="/mission" element={<Mission />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/community" element={<Community />} />

          {/* Check if the user is logged in, if yes then redirect to home */}
          {auth && auth.currentUser ? (
            <Navigate to="/home" replace />
          ) : (
            <Route exact path="/" element={<Landing />} />
          )}

          {/* Check if the user is logged in, if yes then redirect to home */}
          {auth && auth.currentUser ? (
            <Navigate to="/home" replace />
          ) : (
            <Route exact path="/register" element={<Register />} />
          )}

          <Route exact path="/home" element={<Home />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/jobs" element={<Jobs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
