import React from 'react'; 
import "./careers.scss";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Community = () => {
  return (
    <div className="careers">
        <Navbar/>
        <div className="body">
            <h1>Careers</h1>
            <p>
                Exciting career opportunities coming soon! We are growing and looking for talented individuals to join our team. Stay tuned for more updates…
            </p>
        </div>
        <Footer/>
    </div>
  );
};
export default Community;