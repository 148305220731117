import React, { useState } from 'react'; 
import "./homenavbar.scss";
import { NavLink } from 'react-router-dom';

import logo from '../../assets/Tadpole Logo no Back.png';
import notification from '../../assets/notification.svg';
import profile from '../../assets/profile.svg';


const Navbar = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);

  const toggleNotification = () => {
    setNotificationOpen(!notificationOpen);
  };

  return (
    <div className="homenavbar">
      <div className="left">
        <NavLink to="/home" style={{ textDecoration: "none", color: "#008037"}}><img src={logo}/></NavLink>
      </div>
      
      <div className="center">
        <div className="search">
            <input type="text" placeholder="Search"/>
        </div>
      </div>
      
      <div className="right">
        <div className="notification-container">
          <img src={notification} onClick={toggleNotification} />
          {notificationOpen && (
            <div className="notification-dropdown">
              <div className="notification-item">
                <p>You have a new message</p>
              </div>
              <div className="notification-item">
                <p>Your post has been liked</p>
              </div>
            </div>
          )}
        </div>
        <NavLink to="/profile" style={{ textDecoration: "none", color: "#008037"}}><img class="profile" src={profile}/></NavLink>
      </div>
    </div>
  );
};
export default Navbar;