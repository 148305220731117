import React from 'react'; 
import "./home.scss";

import Homenavbar from '../../components/homenavbar/Homenavbar';
import Leftbar from '../../components/leftbar/Leftbar';
import Posts from '../../components/posts/Posts';
import Rightbar from '../../components/rightbar/Rightbar';

const Home = () => {
  return (
    <div className="home">
      <div className="homedesktop">
        <Homenavbar/>
        <div className="body">
          <div className="leftbar">
            <Leftbar/>
          </div>
          <div className="posts">
            <Posts/>
          </div>
          <div className="rightbar">
            <Rightbar/>
          </div>  
        </div>
      </div>

      <div className="homemobile">
        <Homenavbar/>
        <div className="body">
        <div className="leftbarmobile">
            <Leftbar/>
          </div>
          <div className="posts">
            <Posts/>
          </div>  
        </div>
      </div>
    </div>

    
  );
};

export default Home;