import React from 'react'; 
import "./post.scss";

import { NavLink } from "react-router-dom";
import Comments from "../comments/Comments";
import { useState } from "react";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const Post = ({ post }) => {
  const [commentOpen, setCommentOpen] = useState(false);

  //TEMPORARY
  const liked = false;

  return (
    <div className="post">
      <div className="postdesktop">
        <div className="container">
          <div className="user">
            <div className="userInfo">
              <img src={post.profilePic} alt="" />
              <div className="details">
                <NavLink to={`/profile/${post.userId}`} style={{ textDecoration: "none", color: "inherit" }}>
                  <span className="name">{post.name}</span>
                </NavLink>
                <span className="date">1 min ago</span>
              </div>
            </div>
            <MoreHorizIcon />
          </div>
          <div className="content">
            <p>{post.desc}</p>
            <img src={post.img} alt="" />
          </div>
          <div className="info">
            <div className="item">
              {liked ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
              12
            </div>
            <div className="item" onClick={() => setCommentOpen(!commentOpen)}>
              <TextsmsOutlinedIcon />
              12
            </div>
            <div className="item">
              <ShareOutlinedIcon />
              Share
            </div>
          </div>
          {commentOpen && <Comments/>}
        </div>
      </div>

      <div className="postmobile">
        <div className="container">
          <div className="user">
            <div className="userInfo">
              <img src={post.profilePic} alt="" />
              <div className="details">
                <NavLink to={`/profile/${post.userId}`} style={{ textDecoration: "none", color: "inherit" }}>
                  <span className="name">{post.name}</span>
                </NavLink>
                <span className="date">1 min ago</span>
              </div>
            </div>
            <MoreHorizIcon />
          </div>
          <div className="content">
            <p>{post.desc}</p>
            <img src={post.img} alt="" />
          </div>
          <div className="info">
            <div className="item">
              {liked ? <FavoriteOutlinedIcon /> : <FavoriteBorderOutlinedIcon />}
              12
            </div>
            <div className="item" onClick={() => setCommentOpen(!commentOpen)}>
              <TextsmsOutlinedIcon />
              12
            </div>
            <div className="item">
              <ShareOutlinedIcon />
              Share
            </div>
          </div>
          {commentOpen && <Comments/>}
        </div>
      </div>
    </div>
  );
};

export default Post;
