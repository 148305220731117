import React from 'react'; 
import "./footer.scss";
import { NavLink } from 'react-router-dom';

import Landing from '../../pages/landing/Landing';
import About from '../../pages/about/About';
import Mission from '../../pages/mission/Mission';

import logo from '../../assets/Tadpole Logo no Back.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="links">
        <div className="left">
          <NavLink to="/about" style={{ textDecoration: "none", color: "#008037"}}><h3>About</h3></NavLink>
          <NavLink to="/community" style={{ textDecoration: "none", color: "#008037"}}><h3>Community</h3></NavLink>
          <NavLink to="/careers" style={{ textDecoration: "none", color: "#008037"}}><h3>Careers</h3></NavLink>
        </div>
        <div className="center">
          <NavLink to="/" style={{ textDecoration: "none", color: "#008037"}}><img src={logo}/></NavLink>
          <NavLink to="/" style={{ textDecoration: "none", color: "#008037"}}><h3>Tadpole</h3></NavLink>
        </div>
        <div className="right">
          <NavLink to="/support" style={{ textDecoration: "none", color: "#008037"}}><h3>Support</h3></NavLink>
          <NavLink to="/contact" style={{ textDecoration: "none", color: "#008037"}}><h3>Contact Us</h3></NavLink>
          <NavLink to="/mission" style={{ textDecoration: "none", color: "#008037"}}><h3>Mission</h3></NavLink>
        </div>
      </div>
      
      <div className="tag">
        <h3>©Tadpole, Inc. 2022. Leap Forward.</h3>
      </div>
    </div>
  );
};

export default Footer;