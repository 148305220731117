import React from 'react'; 
import "./profile.scss";

import Homenavbar from '../../components/homenavbar/Homenavbar';
import Leftbar from '../../components/leftbar/Leftbar';
import Profileposts from '../../components/profileposts/Profileposts';
import Rightbar from '../../components/rightbar/Rightbar';
import mousa from '../../assets/Mousa.jpg';

const Profile = () => {
  return (
    <div className="profile">
        <Homenavbar/>
        <div className="body">
          <div className="leftbar">
            <Leftbar/>
          </div>
          <div className="middle">
            <div className="contain">
              <div className="uInfo">
                <div className="left">
                  <img src={mousa}/>
                </div>
                <div className="right">
                  <div className="info">
                    <h1>Mousa Adely</h1>
                    <h2>Co-Founder & CEO</h2>
                    <h3>Tadpole | New York, NY</h3>
                  </div>
                    <button>Follow</button>
                </div>
              </div>
            </div>
            <Profileposts/>
          </div>
          <div className="rightbar">
            <Rightbar/>
          </div>  
        </div>
      </div>
  );
};

export default Profile;