import React, { useState, useEffect } from 'react';
import "./jobs.scss";

import Homenavbar from '../../components/homenavbar/Homenavbar';
import Leftbar from '../../components/leftbar/Leftbar';
import Joblistings from '../../components/joblistings/Joblistings';
import Rightbar from '../../components/rightbar/Rightbar';

const Jobs = () => {
  return (
    <div className="jobs">
        <div className="jobsdesktop">
            <Homenavbar/>
            <div className="body">
                <div className="leftbar">
                    <Leftbar/>
                </div>
                <div className="joblist">
                    <Joblistings/>
                </div>
                <div className="rightbar">
                    <Rightbar/>
                </div>  
            </div>
        </div>

        <div className="jobsmobile">
            <Homenavbar/>
            <div className="body">
                <div className="leftbarmobile">
                    <Leftbar/>
                </div>
                <div className="posts">
                    <Joblistings/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Jobs;
