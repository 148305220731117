import React from 'react'; 
import { NavLink } from 'react-router-dom';
import { useState } from "react";

import { auth, googleProvider } from "../../firebase.js"
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth"

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import "./register.scss";

export const Register = () => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const regist = async () => {
    try{
      await createUserWithEmailAndPassword(auth, email, password)
    }
    catch(err){
      console.error(err);
    }
  };

  const signInWithGoogle = async () => {
    try{
      await signInWithPopup(auth, googleProvider)
    }
    catch(err){
      console.error(err);
    }
  };

  return (
    <div className="register">
    <Navbar/>
      <div className="text">
          <h1>Welcome to Tadpole</h1>
      </div>
      <div className="loginBox">   
          <form>
            <table>
              <tr className="names">
                <td>
                  <input 
                    type="text" 
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}/>
                </td>
                <td>
                  <input 
                    type="text" 
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}/>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                <input 
                    type="text" 
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}/>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                <input 
                    type="password" 
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}/>
                </td>
              </tr>
            </table>
          <button onClick={regist}>Register</button>
          </form>  

          <button class="googleSignIn" onClick={signInWithGoogle}>Login With Google</button>
          <h3>Already have an account?</h3>
          <NavLink to="/login" style={{ textDecoration: "none", color: "#008037"}}><button className="loginButton">Login</button></NavLink>
      </div>
      <Footer/>
    </div>
  );
};
export default Register;